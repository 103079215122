import React from 'react';

export const Pulse = () =>{
  return(
    <div class="pulse">
      <span style={{"--i":1}}></span>
      <span style={{"--i":2}}></span>
      <span style={{"--i":3}}></span>
    </div>
  )
}